/* eslint-disable */

import React, { Component } from "react";
import validator from "validator";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import Changepassword from "./Changepassword";
import Billingaddress from "./Billingaddress";
import { Link } from "react-router-dom";
import {
  stripslashes,
  addressFormat,
  showAlert,
  callImage,
  showCustomAlert,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";

import {
  GET_CHANGEPASSWORD,
  GET_ALLUSERSECADDRDATA,
  ADD_USERSECADDRDATA,
} from "../../actions";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import $ from "jquery";

var qs = require("qs");



class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Loading",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
    };
    this.changepassword = this.changepassword.bind(this);
  }

  componentDidMount() {
    /*axios.get(apiUrl + "customer/get_all_user_secondary_address?app_id=" + appId + "&status=A&refrence=" + cookie.load('UserId')).then(res => {
            if (res.data.status === "ok") {
                this.setState({ secAddrData: res.data.result_set });
            } else {
                this.setState({ secAddrData: '', secAddrData: [] });
            }
        }); */
  }

  componentWillReceiveProps(nextProps) {


    if (nextProps.changepassword !== this.props.changepassword) {
      //  const {changepassword} = nextProps.changepassword;
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
         showAlert("Success", "PIN changed successfully!");
      } else {
        if (nextProps.changepassword[0].form_error) {
           showAlert("Error", nextProps.changepassword[0].form_error);
        } else {
           showAlert("Error", nextProps.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }


  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };



  /* Change Password */
  changepassword() {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      passwordtype: "PIN",
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
  }


  render() {

    
    var currenturl = window.location.href;
    var myaccount = "myaccount";
    var myorders = "myorders";
    var rewards = "rewards";
    var mypromotions = "mypromotions";
    var myvouchers = "myvouchers";

    

    if (typeof this.props.customerdetail !== undefined) {

      return (<div className="setting_menu_list">
        <ul className="setting_menu_list">
          <li className={(currenturl.includes(myaccount) === true?"active":"")}>
            <a
              href="/myaccount" className={"right-arrow "+(currenturl.includes(myaccount) === true?"active":"")}
            >
              <span>Account Information</span>
              {currenturl.includes(myaccount) === true && <i class="fa fa-chevron-right"></i>}
            </a>

          </li> 
          <li className={(currenturl.includes(myorders) === true?"active":"")}>
            <a href="/myorders" className={"right-arrow "+(currenturl.includes(myorders) === true?"active":"")}
            >
              <span>My Orders</span>
              {currenturl.includes(myorders) === true && <i class="fa fa-chevron-right"></i>}
            </a>

          </li>
          <li className={(currenturl.includes(rewards) === true?"active":"")}>
            <a href="/rewards" className={"right-arrow "+(currenturl.includes(rewards) === true?"active":"")}
            >
              <span>Rewards</span>
              {currenturl.includes(rewards) === true && <i class="fa fa-chevron-right"></i>}
            </a>

          </li>
          <li className={(currenturl.includes(mypromotions) === true?"active":"")}>
            <a href="/mypromotions" className={"right-arrow "+(currenturl.includes(mypromotions) === true?"active":"")}
            ><span>Promotions</span>
            {currenturl.includes(mypromotions) === true && <i class="fa fa-chevron-right"></i>}
            </a>

          </li> 
          <li className={(currenturl.includes(myvouchers) === true?"active":"")}>
            <a href="/myvouchers" className={"right-arrow "+(currenturl.includes(myvouchers) === true?"active":"")}
            ><span>My Vouchers</span> 
            {currenturl.includes(myvouchers) === true && <i class="fa fa-chevron-right"></i>}
            </a>

          </li>
          <li>
            <a href="#change-password-popup" className="open-popup-link right-arrow"
            >Settings</a>
          </li>
          <li> 
            <a href="/logout" className="right-arrow"><span>Logout</span></a>
          </li>
        </ul>



        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">Change PIN</h3>
            <Changepassword
              fields={this.state.fieldschpassword}
              onChange={this.fieldChange}
              onValid={this.changepassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>

      </div>
      );
    } else {
      return <div id="dvLoading"></div>;
    }
  }
}

const mapStateToProps = (state) => {


  return {
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
