/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GET_REQUESTPAGEDATA,GET_RES_ORDER_SUBMIT} from '../../actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import moment from 'moment';
import {appId,apiUrl,reservationId} from "../Helpers/Config";
import QrReader from 'react-qr-reader';
import tickImg from "../../common/images/tick.png";


class Scanqrcode extends Component {

    constructor(props) {
        super(props);
			this.state = {pagedetail:''};
    }

	
	componentDidMount(){
		
		
	}
   handleScan = data => {
		
	     var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	    '(\\#[-a-z\\d_]*)?$','i');

	    if (pattern.test(data)) {
	      window.location.href = data;
	    }
	}

	  handleError = err => {
	   // this.setState({dine_in_click: "No"});
	    //$.magnificPopup.close();
	  }

    render() {
		return (<div className="dinein-scantable">
                <p> <a href="/">&#60;</a>Scan QR code and confirm your table</p>
                <div id="qr_code" className="dinein-scantable-inner">
                  <QrReader
                  delay={1000}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%' }}
                  />
                </div>
                {/*Dine In Errorpopup for scan*/}
                <div id="dinein-url-popup" className="white-popup mfp-hide popup_sec warning_popup">
                    <div className="custom_alert">
                      <div className="custom_alertin">
                        <div className="alert_height">
                          <div className="alert_header">Information</div>
                          <div className="alert_body">
                            <p>You are trying to Scan wrong QR code</p>
                             <div className="alt_btns alt-btns">
                                <a href="#" className="button button-left popup-modal-dismiss disbl_href_action"
                                >Ok
                                </a>
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </div>);
    }
}




export default (Scanqrcode);

 
