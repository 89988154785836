/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { SET_WHATS_HAPPENING, GET_WHATS_HAPPENING } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';


export const watchGetWhatsHappening = function* () {
  yield takeEvery(GET_WHATS_HAPPENING, workerGetWhatsHappening);
}

function* workerGetWhatsHappening() {
  try {
    const uri = apiUrl+'cms/whats_happening?app_id='+ appId;
    const result = yield call(Axios.get, uri);
	  var resultArr = [];
		resultArr.push(result.data);
    yield put({ type: SET_WHATS_HAPPENING, value: resultArr });
  } 
  catch {
    console.log('Whats Happening failed');
  }
} 

