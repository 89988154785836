/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";
import member from "../../common/images/member.png";
import bronze from "../../common/images/bronze.png";
import silver from "../../common/images/silver.png";
import banner from "../../common/images/inner-banner.jpg";
import gold from "../../common/images/gold.png";
import like from "../../common/images/like.png";

var dateFormat = require("dateformat");

import validator from "validator";
import update from "immutability-helper";
import { validated } from "react-custom-validation";

import Select from "react-select";
import Moment from "moment";

/* import 'react-select/dist/react-select.css';*/

import {
  stripslashes,
  addressFormat,
  showAlert,
  callImage,
  showCustomAlert,
} from "../Helpers/SettingHelper";

import {
  GET_CUSTOMER_DETAIL,
  GET_UPDATECUSTOMERPROFILE,
  GET_ACTIVITYCOUNT,
} from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Menumobile from "./Menumobile";

import Form from "./Form";


var qs = require("qs");

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class Myaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        nickname: "",
        email: "",
        mobile: "",
        postal: "",
        membership: "",
        unitnumber2: "",
        unitnumber1: "",
        company: "",
        birthdate: "",
        fbid: "",
        image_source: "",
        photo: "",
        gender: "",
      },
      status: "OK",
      date1: "",
      defaultDispaly: "",
      order_all: 0,
      overall_orders: 0,

      activityPoints: {
        reward_ponits: "",
        reward_ponits_monthly: "",
        order_all: "",
        promotion: "",
      },
      ordercount: 0,
    };

    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    var params = "&status=A&refrence=" + cookie.load("UserId");
    $("#dvLoading").fadeOut(2000);
    this.props.getCustomerDetail(params);
    this.getActivityCountsNew();

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 400);
  }

  getActivityCountsNew() {
    const inputKeys = ["order_all", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }
 
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_last_name: formPayload.lastname,
      customer_nick_name: formPayload.nickname,
      customer_phone: formPayload.mobile,
      customer_email: formPayload.email,
      customer_birthdate: cookie
        .load("birthdate")
        .replace("/", "-")
        .replace("/", "-"),
      customer_postal_code: formPayload.postal,
      customer_address_name2: formPayload.address1,
      customer_company_name: formPayload.company,
      customer_gender: formPayload.gender.value,
      customer_address_line1: formPayload.unitnumber1,
      customer_address_line2: formPayload.unitnumber2,
      customer_id: cookie.load("UserId"),
    };
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    this.props.getUpdateCustomerProfile(qs.stringify(postObject));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.updatecustomerprofile !== this.props.updatecustomerprofile) {
      const { updatecustomerprofile } = nextProps;
      if (updatecustomerprofile[0].status === "ok") {
        $(".myaccount_update .gloading_img").remove();
        cookie.save(
          "UserFname",
          updatecustomerprofile[0].result_set.customer_first_name,
          { path: "/" }
        );
        cookie.save(
          "UserLname",
          updatecustomerprofile[0].result_set.customer_last_name,
          { path: "/" }
        );
        cookie.save(
          "UserMobile",
          updatecustomerprofile[0].result_set.customer_phone,
          { path: "/" }
        );
        cookie.save(
          "UserEmail",
          updatecustomerprofile[0].result_set.customer_email,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultAddress",
          updatecustomerprofile[0].result_set.customer_address_name,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultUnitOne",
          updatecustomerprofile[0].result_set.customer_address_line1,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultUnitTwo",
          updatecustomerprofile[0].result_set.customer_address_line2,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultPostalCode",
          updatecustomerprofile[0].result_set.customer_postal_code,
          { path: "/" }
        );
        cookie.save(
          "UserMembership",
          updatecustomerprofile[0].result_set.customer_membership_type,
          { path: "/" }
        );

        /* Redirect to checkout page .. */
        if (
          cookie.load("isChecoutRedirect") === "Yes" &&
          cookie.load("cartValue") === "Yes"
        ) {
          cookie.save("isChecoutRedirect", "No", { path: "/" });
          /*hashHistory.push("/checkout");*/
        }
        showAlert("success", "Your Profile Has Been Updated Successfully");
      } else {
        if (updatecustomerprofile[0].form_error) {
          showAlert("error", updatecustomerprofile[0].form_error);
        } else {
          showAlert("error", updatecustomerprofile[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {
      //const {customerdetail} = nextProps;
      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        if (
          nextProps.customerdetail[0].result_set.customer_birthdate == null ||
          nextProps.customerdetail[0].result_set.customer_birthdate ==
            "0000-00-00"
        ) {
          var birthdate = "";
        } else {
          var birthdate =
            nextProps.customerdetail[0].result_set.customer_birthdate;
          var birthyear = birthdate.substring(0, 4);
          var birthmonth = birthdate.substring(5, 7);
          var birthdatev = birthdate.substring(8, 10);
          var birthdateTemp = birthdatev + "/" + birthmonth + "/" + birthyear;
          cookie.save("birthdate", birthdateTemp, { path: "/" });
        }

        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            birthdate: birthdate,
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            nickname:
              nextProps.customerdetail[0].result_set.customer_nick_name !== null
                ? nextProps.customerdetail[0].result_set.customer_nick_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",
            photo:
              nextProps.customerdetail[0].result_set.customer_photo !== null &&
              nextProps.customerdetail[0].result_set.customer_photo !== ""
                ? nextProps.customerdetail[0].common.image_source +
                  nextProps.customerdetail[0].result_set.customer_photo
                : "",
            postal:
              nextProps.customerdetail[0].result_set.customer_postal_code !==
              null
                ? nextProps.customerdetail[0].result_set.customer_postal_code
                : "",
            membership:
              nextProps.customerdetail[0].result_set.customer_membership_type !==
              null
                ? nextProps.customerdetail[0].result_set.customer_membership_type
                : "",
            unitnumber2:
              nextProps.customerdetail[0].result_set.customer_address_line2 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_line2
                : "",
            unitnumber1:
              nextProps.customerdetail[0].result_set.customer_address_line1 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_line1
                : "",
            address:
              nextProps.customerdetail[0].result_set.customer_address_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_name
                : "",
            address1:
              nextProps.customerdetail[0].result_set.customer_address_name2 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_name2
                : "",
            company:
              nextProps.customerdetail[0].result_set.customer_company_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_company_name
                : "",
            fbid: nextProps.customerdetail[0].result_set.customer_fb_id,
            image_source: nextProps.customerdetail[0].common.image_source,
            juicedid:
              nextProps.customerdetail[0].result_set.customer_unique_id !== null
                ? nextProps.customerdetail[0].result_set.customer_unique_id
                : "",
            joinedOn:
              nextProps.customerdetail[0].result_set.customer_created_on !==
              null
                ? nextProps.customerdetail[0].result_set.customer_created_on
                : "",
            gender:
              nextProps.customerdetail[0].result_set.customer_gender !== null &&
              nextProps.customerdetail[0].result_set.customer_gender !== ""
                ? nextProps.customerdetail[0].result_set.customer_gender
                : "M",
          },
          status: nextProps.customerdetail[0].status,
        });

        var vDefaultAddr = "";
        var list = document.getElementsByClassName("form-group-input");
        var n;
        for (n = 0; n < list.length; ++n) {
          if (list[n].getElementsByTagName("input")[0].value !== "") {
            list[n].classList.remove("is-empty");
          } else {
            list[n].classList.add("is-empty");
          }
        }
      }
    }

    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
          });
        }
      }
    } else {
      this.setState({ order_all: 0, overall_orders: 0 });
    }
  }
  
  showMembershipDesc(memberShpType) {
      if(memberShpType === 'Gold') {
        return(<p>12% Cashback for every online order.</p>);
      } else if(memberShpType === 'Silver') {
        return(<p>8% Cashback for every online order.</p>);
      } else {
        return(<p>5% Cashback for every online order.</p>);   
      }
  }

  render() {
    
    let customer_membership_type = "";
      let customer_membership_type_display = "";
      let customer_unique_id = "";
      let calc_kakis_perc = 0;
      let calc_kakis_perc_display = "";
      let customer_membership_displayname = "";

      if (Object.keys(this.props.customerdetail).length) {
        customer_membership_type = this.props.customerdetail[0].result_set
          .customer_membership_type;
        customer_membership_displayname = this.props.customerdetail[0].result_set
          .customer_membership_displayname;          
        customer_unique_id = this.props.customerdetail[0].result_set
          .customer_unique_id;

        let membership_max_amount = this.props.customerdetail[0].result_set
          .membership_max_amount;
        let membership_spent_amount = this.props.customerdetail[0].result_set
          .membership_spent_amount;
        let membership_spent_msg = this.props.customerdetail[0].result_set
          .membership_spent_msg;

        if (
          customer_membership_type !== "" && typeof customer_membership_displayname != "undefined"
        ) {
          customer_membership_type_display = (
             <div>
             <img src={member} />
             <p>{customer_membership_displayname}</p>
              </div>
          );
        }else{
          customer_membership_type_display = (
             <div>
             <img src={member} />
             <p>Spring</p>
              </div>
          );
        }


        if (parseInt(membership_max_amount)) {
          calc_kakis_perc =
            (membership_spent_amount / membership_max_amount) * 100;
        }
        if (typeof membership_max_amount != "undefined" && membership_max_amount) {
          calc_kakis_perc_display = (
            <div className="member-prog-bar">
            <div className="member-progress-2">
            <div className="member-bar-2 member-bar-width-2" style={{ width: calc_kakis_perc + "%" }}>
            </div>
            </div>
            <p className="member-help-block">{membership_spent_msg}</p>
            </div>            
          );
        }else{
          calc_kakis_perc_display = (          
            <div className="member-prog-bar">
            <div className="member-progress-2">
            <div className="member-bar-2 member-bar-width-2" style={{ width: "10%" }}>
            </div>
            </div>
            <p className="member-help-block">SPEND $500 MORE TO BECOME A SUMMER MEMBER</p>
            </div> 
          );
        }
      }

    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
           <img src={banner} />
            <p className="hajja-myacc">My Account</p>
            <span className="hajja-myacc-span">
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout">
              <Menumobile />
              </div>

              <div className="memb-foot">
   <div className="memb-payment">
      <div className="memb-payment-row">
         <div className="memb-payment-col">
         <div className="memb-payment-col-radio">
               <div className="col-md-12">
             
                  <div className="col-md-3 memb-first">
                    {customer_membership_type_display}
                  </div>
                  <div className="col-md-3 memb-bord">
                  <div className="memb-bord-inner">
                    <h3>Member Benefits</h3>
          {this.showMembershipDesc(customer_membership_type)}
                   </div>
                  </div>
                  <div className="col-md-6 memb-second">
                  <ul className="memb-payment-col-radio">
                  <li className="custom_checkbox">
                  <div>
                   <img src={bronze} />
                   <p>Spring</p>
                    </div> 
                  </li>
                  <li className="custom_checkbox">
                  <div>
                   <img src={silver} />
                   <p>Summer</p>
                    </div> 
                  </li>
                  <li className="custom_checkbox">
                  <div>
                   <img src={gold} />
                   <p>Autumn</p>
                    </div> 
                  </li>
                   {calc_kakis_perc_display}
               </ul>
                  </div>
               </div>
               </div>
         
           
         </div>
      </div>
   </div>
</div>

              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            <Form
                              {...this.props}
                              fields={this.state.fields}
                              activityPoints={this.state.activityPoints}
                              onChange={this.fieldChange}
                              onValid={this.handleFormSubmit}
                              onInvalid={() => console.log("Form invalid!")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerdetail: state.customerdetail,
    updatecustomerprofile: state.updatecustomerprofile,
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    getUpdateCustomerProfile: (formPayload) => {
      dispatch({ type: GET_UPDATECUSTOMERPROFILE, formPayload });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myaccount)
);
