/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { appId, deliveryId, pickupId, apiUrlV2, dineinId, apiUrl } from "../Helpers/Config";
import {
  showAlert
} from "../Helpers/SettingHelper";


class Booktable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table_number:''
    };

  }

  componentDidMount() {

    var tablenumber = this.props.match.params.tablenumber;
    this.setState({ table_number: tablenumber });
    if (typeof tablenumber === "undefined" || tablenumber === "") {
      cookie.save("defaultAvilablityId", dineinId, { path: "/" });
      showAlert("Error", "Please check your QR code.");
      history.push("/");
    }else{
       cookie.save("defaultAvilablityId", dineinId, { path: "/" });
       this.findDineintable(tablenumber);
    }

  }

   findDineintable (tablenumber) {

    var table_number = tablenumber;

    if(table_number !== "") {
      cookie.save('orderTableNo', table_number,{ path: '/' });
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        tm_table_number: table_number,
      };
      axios
      .post(apiUrl + "tablemanagement/find_table", qs.stringify(postObject))
      .then((response) => {
        if(response.data.status === 'ok'){
          var outlet_name = response.data.result_set[0].outlet_name;
          var outlet_id = response.data.result_set[0].outlet_id;
          var outlet_unit_number1 = response.data.result_set[0].outlet_unit_number1;
          var outlet_unit_number2 = response.data.result_set[0].outlet_unit_number2;
          var outlet_address_line1 = response.data.result_set[0].outlet_address_line1;
          var outlet_address_line2 = response.data.result_set[0].outlet_address_line2;
          var outlet_postal_code = response.data.result_set[0].outlet_postal_code;
          var outlet_dine_tat = response.data.result_set[0].outlet_dine_tat;

          if(outlet_unit_number1 != '' && outlet_unit_number2 != '') {
            var unitTxt = ", #" + outlet_unit_number1 + "-" + outlet_unit_number2;
            var orderHandled = outlet_name+" "+outlet_address_line1+" "+outlet_address_line2+unitTxt+", Singapore "+outlet_postal_code;
         } else if(outlet_unit_number1 != '' || outlet_unit_number2 != '') {
            var unitTxt = (outlet_unit_number1 != '') ? ", #" + outlet_unit_number1 : ", #" + outlet_unit_number2;
            var orderHandled = outlet_name+" "+outlet_address_line1+" "+outlet_address_line2+unitTxt+", Singapore "+outlet_postal_code;
         } else {
           var orderHandled = outlet_name+" "+outlet_address_line1+" "+outlet_address_line2+", Singapore "+outlet_postal_code;
         }
          
          this.setState({orderHandled:orderHandled});
          cookie.save('orderOutletId',outlet_id, { path: "/" });
          cookie.save('orderOutletName',outlet_name, { path: "/" });
          cookie.save('orderPostalCode',outlet_postal_code, { path: "/" });
          cookie.save('orderTAT',outlet_dine_tat, { path: "/" });
          cookie.save('orderHandled',orderHandled, { path: "/" });
          cookie.save('orderHandledByText',orderHandled, { path: "/" });

           window.location.href ="/products";

        }else{
            
            $.magnificPopup.open({
            items: {
            src: '#dinein-error-popup',
            },
            type: "inline",
            })
            return false;
        }
      });
    } else {
      $.magnificPopup.open({
        items: {
          src: '#dinein-error-popup',
        },
        type: "inline",
      })
        return false;
    }

 }

 backtosite(){
  window.location.href = '/';
 }
  render() {
     return (
        <div>
          
             {/*Dine In Errorpopup for scan*/}
                <div
                    id="dinein-error-popup"
                    className="white-popup mfp-hide popup_sec warning_popup"
                  >
                    <div className="custom_alert">
                      <div className="custom_alertin">
                        <div className="alert_height">
                          <div className="alert_header">Information</div>
                          <div className="alert_body">
                            <p>Table number is not exists!</p>
                            <div className="alt_btns alt-btns">
                              <a href="/#" onClick={this.backtosite.bind(this)} className="button button-left popup-modal-dismiss disbl_href_action"
                              >Ok
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          {/*Dine In Errorpopup for scan - End*/}
        </div>
      );
    }
}

export default Booktable;
