/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { stripslashes } from "../Helpers/SettingHelper";

import {
  appId,
  apiUrl,
  apiUrlV2,
  timThumpUrl,
  deliveryId,
  reservationId,
  mediaUrl,
} from "../Helpers/Config";

var Parser = require("html-react-parser");

import update from "immutability-helper";

import { validated } from "react-custom-validation";
import validator from "validator";

import axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
 import banner from "../../common/images/inner-banner.jpg";
import noimage from "../../common/images/noimg-470x240.jpg";
import innerBanner from "../../common/images/inner-banner.jpg";

import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
    };
  }

  componentDidMount() {
    this.props.getAllOutlets(deliveryId);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  chooseOrder(){

      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });

  }

  tConvert(time) {
    if (
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }

  allInOnepopup() {
    cookie.save("defaultAvilablityId", reservationId, { path: "/" });
    this.props.history.push("/reservation");
  }

  gotoContacus(outletId) {
    cookie.save("contusOutletId", outletId, { path: "/" });
    this.props.history.push("/contact-us");
  }

  callPhoneOptn(phoneTxt) {
    var resultTxt = "";
    console.log(phoneTxt, "phoneTxt");
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }

  timingCnt() {
    return <span>Timing: 7 days a week | 10:30am - 7:00pm</span>;
  }

  getOutletData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item, index) => {
        return (
          <li key={index}>
            <div className="ourrest_row">
              <div className="ourrest_img">
                {item.outlet_image !== "" &&
                typeof item.outlet_image !== undefined &&
                typeof item.outlet_image !== "undefined" ? (
                  <img
                    src={mediaUrl + "outlet/" + item.outlet_image}
                    alt="Outlet Img"
                  />
                ) : (
                  <img className="media-object" src={noimage} />
                )}
              </div>
              <div className="ourrest_info">
                <h4>{stripslashes(item.outlet_name)}</h4>
                <p><i class="fa fa-fw  fa-map-marker"></i>
                  {item.outlet_address_line1}
                  {item.outlet_unit_number2 !== ""
                    ? "#" +
                      item.outlet_unit_number1 +
                      "-" +
                      item.outlet_unit_number2
                    : item.outlet_unit_number1}{" "}
                  Singapore - {item.outlet_postal_code}
                </p>
                  {item.outlet_email !== "" && (
                <p>
                    <span>
                      <i className="fa fa-fw  fa-envelope"></i> {item.outlet_email} 
                    </span>
                </p>
                  )}
                <p>
                <i class="fa fa-fw  fa-phone"></i>
                  {" "}
                  <span className="phone-show-dskp">{item.outlet_phone}</span>
                  <span className="phone-show-mbl">
                    {" "}
                    <a href={this.callPhoneOptn(item.outlet_phone)}>
                      {item.outlet_phone}
                    </a>
                  </span>{" "}
                </p>
                <p>
                  <i class="fa fa-fw  fa-clock-o"></i>{this.timingCnt(item.outlet_id)}
                </p>

                <ul className="ourrest_infolinks">
                  <li className="media-links-b li-full-width">
                    <a
                      href="javascript:void(0)"
                      rel="nofollow"
                      onClick={this.chooseOrder.bind()}
                      className="readmore font-headings"
                    >
                      <i className="fa fa-cutlery"></i>Make A Order Now{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        );
      });
    }
  };

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
          <img src={banner} />
            <p>Our Outlets</p>
          </div>
        </div>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
